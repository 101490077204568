import React from 'react';
import './GoogleReviewPage.css'; // Assuming you have a CSS file for this component

const GoogleReviewPage = () => {
  return (
    <div className="form-container">
      <div className="form-box">
        <h2>Leave Us a Google Review</h2>
        <p>We appreciate your feedback!</p>
        <a href="https://www.google.com/search?q=show+gear+productions#lrd=0x80dceba1b81f5db7:0x2339bcd58af3c4fa,3,,,," target="_blank" rel="noopener noreferrer" className="google-review-link">
          Click here to review us on Google
        </a>
        {/* If you have an embeddable Google review form, you can insert it here */}
      </div>
    </div>
  );
};

export default GoogleReviewPage;
