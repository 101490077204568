import React, { useState, useEffect } from 'react';
import Rating from 'react-rating-stars-component';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const isMobileDevice = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

const MainComponent = () => {
  const [rating, setRating] = useState(0);
  const [booth, setBooth] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const boothFromQuery = searchParams.get('booth');
    if (boothFromQuery) {
      setBooth(boothFromQuery);
    }
  }, [location]);

  const onRating = async (newRating) => {
    setRating(newRating);
    if (newRating < 5) {
      navigate(`/form?rating=${newRating}&booth=${booth}`);
    } else {
     const mobileUrl = "https://www.google.com/search?q=show+gear+productions+reviews&si=ALGXSlYWsjFmaPLJzT6890bFu84dn80YhtIs5Az7ElrpwzXPNcEB-EH3v796DVRe0oOPQMcW--Aj50b6qHrvkf_kMc2zuDCISD4TKtclvmmewmCgF7ODq2WFJTOlwo2cSGqVj_Y4A1ty5eh5TgsCCcce3ywjmLN56g%3D%3D&ictx=1&ved=2ahUKEwjEnOGT8P2CAxXqsFYBHTA5B-8QyNoBKAF6BAgREAg#lrd=0x80dceba1b81f5db7:0x2339bcd58af3c4fa,3,,,,"
     const desktopUrl = 'https://www.google.com/search?q=show+gear+productions#lrd=0x80dceba1b81f5db7:0x2339bcd58af3c4fa,3,,,,';
     const url = isMobileDevice() ? mobileUrl : desktopUrl;
     window.location.href = url;

     try {
      await axios.post('https://api.airtable.com/v0/appkq9xWmlh6AxjRT/tblUn07LPXOITbFb9', 
        { fields: 
          {
            name: '',
            email: '',
            phone: '',
            booth: booth,
            rating: 5,
            review: '',
          } 
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`
          }
        }
      );
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
    }
  };

  return (
    <div className="form-container">
      <div className="form-box">
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <img height="42" src="https://storage.googleapis.com/sgv-general-storage/sgv/FINAL%20LOGO%20BLACK.png" alt="Logo 1" style={{ marginRight: '10px', marginTop: '15px' }} />
        </div>
        <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Please rate your experience below:</h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Rating onChange={onRating} size={40} /> {/* Adjust the size as needed */}
        </div>
        {/* Additional content (if any) goes here */}
      </div>
    </div>
  );
};

export default MainComponent;
