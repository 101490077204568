import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainComponent from './components/MainComponent';
import FormPage from './components/FormPage';
import GoogleReviewPage from './components/GoogleReviewPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainComponent />} />
        <Route path="/form" element={<FormPage />} />
        <Route path="/google-review" element={<GoogleReviewPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
