import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Rating from 'react-rating-stars-component';
import axios from 'axios';
import './FormPage.css'; // Make sure to create and style this file as described

const FormPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    booth: '',
    rating: 0,
    review: '',
  });

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const boothFromQuery = searchParams.get('booth');
    const ratingFromQuery = searchParams.get('rating');

    if (ratingFromQuery) {
      setFormData(prevFormData => ({
        ...prevFormData,
        booth: boothFromQuery,
        rating: ratingFromQuery ? parseInt(ratingFromQuery, 10) : prevFormData.rating,
      }));
    }
  }, [location.search]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const boothParam = queryParams.get('booth');
    if (boothParam) {
      setFormData(prevFormData => ({
        ...prevFormData,
        booth: boothParam,
      }));
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await axios.post('https://api.airtable.com/v0/appkq9xWmlh6AxjRT/tblUn07LPXOITbFb9', 
        { fields: formData },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`
          }
        }
      );

      if (response.status === 200) {
        setIsSubmitted(true);
        setFormData({
          name: '',
          email: '',
          phone: '',
          booth: '',
          review: '',
        });
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    }
    setIsSubmitting(false);
  };

  if (isSubmitted) {
    return (
      <div className="form-container">
        <div className="form-box">
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <img height="42" src="https://storage.googleapis.com/sgv-general-storage/sgv/FINAL%20LOGO%20BLACK.png" alt="Logo 1" style={{ marginRight: '10px', marginTop: '15px' }} />
                
              </div>
              <h4>Thank you for your comments, a Show Gear Representative will connect with you soon!</h4>
          
        </div>
      </div>
    );
  }

  return (
    <>
      {isSubmitting && (
        <div className="overlay">
          <div className="spinner" />
        </div>
      )}
  
      <div className="form-container">
        <div className="form-box">
          {isSubmitted ? (
            <h3>Thank you for your comments, a Show Gear Representative will connect with you soon!</h3>
          ) : (
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <img height="42" src="https://storage.googleapis.com/sgv-general-storage/sgv/FINAL%20LOGO%20BLACK.png" alt="Logo 1" style={{ marginRight: '10px', marginTop: '15px' }} />
              </div>
  
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} />
  
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} />
  
              <label htmlFor="phone">Phone</label>
              <input type="tel" id="phone" name="phone" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange} />
  
              <label htmlFor="booth">Booth</label>
              <input type="text" id="booth" name="booth" placeholder="Booth Number" value={formData.booth} onChange={handleChange} />

              {formData.rating && (
                <div>
                  <label>Rating:</label>
                  <Rating value={formData.rating} size={20} edit={true} />
                </div>
              )}
  
              <label htmlFor="review">Review</label>
              <textarea id="review" name="review" placeholder="Your Review" value={formData.review} onChange={handleChange} />
  
              <button type="submit" disabled={isSubmitting}>Submit</button>
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
          )}
        </div>
      </div>
    </>
  );
  
}

export default FormPage;
