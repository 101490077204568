// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(247, 239, 252);
  }
  
  .form-box {
    text-align: center;
    width: 600px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .google-review-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #562f97; /* A variation of dark purple for the button */
    color: white;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .google-review-link:hover {
    background-color: #2f0281; /* Darker purple on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/GoogleReviewPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,oCAAoC;EACtC;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,2CAA2C;IAC3C,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB,EAAE,8CAA8C;IACzE,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB,EAAE,2BAA2B;EACxD","sourcesContent":[".form-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: rgb(247, 239, 252);\n  }\n  \n  .form-box {\n    text-align: center;\n    width: 600px;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n    background-color: #ffffff;\n  }\n  \n  .google-review-link {\n    display: inline-block;\n    margin-top: 20px;\n    padding: 10px 15px;\n    background-color: #562f97; /* A variation of dark purple for the button */\n    color: white;\n    border-radius: 4px;\n    text-decoration: none;\n  }\n  \n  .google-review-link:hover {\n    background-color: #2f0281; /* Darker purple on hover */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
